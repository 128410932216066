import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleCheck, faFileExcel, faCircleExclamation, faAdd } from "@fortawesome/free-solid-svg-icons";
import Form from "react-validation/build/form";
import * as XLSX from "xlsx";
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from '../../../common/components/spinner';
import EnrolService from '../../../services/enrol.service';
import './enrolmodal.css';
import { saveAs } from 'file-saver';
import { Link } from "react-router-dom";
import ExampleExcel from '../../../files/create-update-example.xlsx';

function EnrolModal() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(false);
  const [showform,setShowform] = useState(true);
  const [showfinish,setShowfinish] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [blockbuttons,setBlockbuttons] = useState(false);

  const handleClose = () => {
    setShow(false);
    setShowform(true);
    setShowfinish(false);
    setShowerror(false);
  };
  const handleShow = () => {
    setShow(true);
    setShowform(true);
    setShowfinish(false);
    setShowerror(false);
  }; 


  const processFile = (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (e) =>{
        const dataExcel = e.target.result;
        const workbook = XLSX.read(dataExcel,{ type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        EnrolService.matricular(parsedData).then(
          response => {
            if (response.hasOwnProperty('exception')) {
              reject();
            } else {
              setData(response);
              resolve(response);
            }
            document.dispatchEvent(new Event("refreshData"));
          },
          error => {
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            console.log(error.toString());
            reject();
            document.dispatchEvent(new Event("refreshData"));
          }
        );
        
      }
      reader.onerror = () => {
        reject('oops, something went wrong with the file reader.')
      }
      reader.readAsBinaryString(file);
    })
  }

  const exportData = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb,ws,'Hoja1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    setData(false);
    saveAs(excelData,'log_upload.xlsx');
    setShow(false);
    setShowform(true);
    setShowfinish(false);
    setShowerror(false);
  };


  const onFormSubmit = (e) => {
    e.preventDefault();
    setBlockbuttons(true);
    setShowform(false);
    trackPromise(
      processFile(e.target[0].files[0]).then((dataExcel) => {
            console.log(dataExcel);
            setBlockbuttons(false);
            setShowfinish(true);
      }).catch((cat)=>{
        setBlockbuttons(false);
        setShowerror(true);
      })
      ,'area-upload-new'
    );

    
  };


  return (
    <>
      <Button variant="primary" className='btn-lg btn-block mb-3'
              onClick={handleShow}>
                <FontAwesomeIcon icon={faAdd} /> Matricular
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" backdrop="static" centered>
        <Modal.Header >
          <Modal.Title><FontAwesomeIcon icon={faAdd} /> Matricular</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showform && (
            <Form onSubmit={onFormSubmit}>
              <div className="form-group">
                <label>En este formulario vas a poder Matricular alumnos y profesores, asegúrate de usar el formato correcto, en caso 
                  no estes seguro o no tengas el formato, puedes descargarlo 
                  dando clic <Link to={ExampleExcel} download="created-updated-example" target="_blank" rel="noreferrer" >AQUÍ</Link> </label>
              </div>
              <div className='form-group'>
                <input type='file'/>
              </div>
              <input type='submit' value="Procesar" className='btn btn-primary' disabled={blockbuttons} />
            </Form>
          )}
          {showfinish && (
            <div>
              <div className="alert alert-success" role="alert">
                El proceso de subida terminó correctamente
              </div>
              <div className='text-center showIconMessage'>
                <FontAwesomeIcon icon={faCircleCheck} className='fa-4x text-success mb-4' beat />
              </div>
              <div className='text-center showIconMessage'>
                Si deseas puedes descargar el resumen de tu subida!
              </div>
              <div className='text-center showIconMessage'>
              <Button variant="success" className='btn-lg mb-3'
                      onClick={exportData}>
                        <FontAwesomeIcon icon={faFileExcel} /> Descargar Reporte
              </Button>
              </div>
            </div>
          )}
          {showerror && (
            <div>
              <div className="alert alert-warning" role="alert">
                hubo un error en la subida, revisa el formato del excel o reportalo al administrador.
              </div>
              <div className='text-center showIconMessage'>
                <FontAwesomeIcon icon={faCircleExclamation} className='fa-4x text-warning mb-4' beat />
              </div>
            </div>
          )}
          
          <Spinner area={'area-upload-new'} />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={blockbuttons}>
            Cerrar
          </Button>
          
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EnrolModal;