import React, { useState, useEffect } from 'react';
import UserService from "../../services/user.service";
import EventBus from "../../common/EventBus";
import { trackPromise } from 'react-promise-tracker';
import { Spinner } from '../../common/components/spinner';

function TableWithPagination() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [primera, setPrimera] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    if (!primera) {
      fetchData();
      setPrimera(true);
    }
  }, []);

  document.addEventListener("refreshData",()=>{
    fetchData();
  })

  const fetchData = async () => {
    trackPromise(
        UserService.getData().then(
          response => {
            setData(response);
          },
          error => {
            setError(error);
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        )
      , 'area-table-resumen');
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginatedData = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <table className="table table-striped">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">DNI</th>
                <th scope="col">Nombres</th>
                <th scope="col">Apellidos</th>
                <th scope="col">Correo</th>
                <th scope="col">Cargo</th>
                <th scope="col">Usuario que registra</th>
                <th scope='col'>Socio</th>
                <th scope="col">Tipo de proceso</th>
                <th scope="col">Mensaje</th>
                <th scope="col">Última modificación</th>
            </tr>
        </thead>
        <tbody>
          {paginatedData.map(item => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.username}</td>
              <td>{item.firstname}</td>
              <td>{item.lastname}</td>
              <td>{item.email}</td>
              <td>{item.cargo}</td>
              <td>{item.asesor}</td>
              <td>{item.socio}</td>
              <td>{item.type_procese}</td>
              <td>{item.message}</td>
              <td>{item.timecreation}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <nav>
        <ul className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
              <button className="page-link" onClick={() => handleChangePage(i + 1)}>
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <Spinner area={'area-table-resumen'} />
    </div>
  );
}

export default TableWithPagination;