import axios from "axios";
import authHeader from './auth-header';

class EnrolService {
    newEnrols(data){
        return this.callService(data,'local_autoservice_enrol_users');
      }

    matricular(data){
    return this.callService(data,'local_autoservice_matricular_users');
    }

    deleteEnrols(data){
        return this.callService(data,'local_autoservice_delete_users');
    }
    suspendEnrols(data){
        return this.callService(data,'local_autoservice_suspend_users');
    }

    getReport(){
        return this.callService(null,'local_autoservice_export');
    }

    callService(data,service){
        var bodyFormData = new FormData();
        bodyFormData.append('wsfunction',service);
        bodyFormData.append('wstoken',process.env.REACT_APP_MOODLE_TOKEN);
        bodyFormData.append('auth',authHeader());
        if(data){
            var count = 0;
            data.forEach( input => {
                Object.keys(input).forEach((e) => {
                    bodyFormData.append('enrolments[' + count + '][' + e + ']',input[e]);
                });
                count++;
            });
        }
        return axios
          .post(process.env.REACT_APP_URL_MOODLE, bodyFormData)
          .then(response => {
            return response.data;
        });
    }
}

export default new EnrolService();