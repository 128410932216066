import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import authService from "../../services/auth.service";
import './home.css'
import AddModal from "../modal/addmodal/addmodal";
import DeleteModal from "../modal/deletemodal/deletemodal";
import SuspendModal from "../modal/suspendmodal/suspendmodal";
import TableWithPagination from  '../table-with-pagination/table-with-pagination';
import ExportReport from "../export-report/export-report";
import EnrolModal from "../modal/enrolmodal/enrolmodal";


export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { username: "" },
      content: { nodes : []},
    };
    
  }


  

  componentDidMount() {
    
    const currentUser = authService.getCurrentUser();


    if (!currentUser) this.setState({ redirect: "/login" });
    this.setState({ currentUser: currentUser, userReady: true })
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }
    return (
      <div className="container">
          <header className="jumbotron">
          </header>
          <div className="row">
            <div className="col-md-3">
              <DeleteModal></DeleteModal>
            </div>
            <div className="col-md-3">
              <SuspendModal></SuspendModal>
            </div>
            <div className="col-md-3">
              <AddModal></AddModal>
            </div>
            <div className="col-md-3">
              <EnrolModal></EnrolModal>
            </div>
            <div className="col-md-3">
              <ExportReport/>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <TableWithPagination/>
              </div>
            </div>
          </div>
          
      </div>
    );
  }
}
